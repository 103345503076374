@import 'core';

// TODO Calculate this
$profile-navigator-height: 130px;

$navbar-profile-bg: #f9f9f9;

html,
body {
  scroll-padding-top: $profile-navigator-height;
}

.profile-details {
  margin-bottom: spacer(2);
}

.profile-identity {
  margin-bottom: spacer(2);
}

.profile-fullname {
  display: inline-block;
  margin-right: spacer(2);
  margin-bottom: spacer(2);
  line-height: 1;
  font-family: $font-family-base;

  @include media-breakpoint-up(sm) {
    margin-right: spacer(3);
  }
}

.profile-title {
  display: block;
  font-size: $h3-font-size;
  font-size: calc(1rem + 2vw);
  margin-top: spacer(1);

  @include media-breakpoint-up(sm) {
    font-size: $h3-font-size;
  }
}

.profile-name {
  display: block;
  margin-top: spacer(2);
  margin-left: -0.05em;
  font-size: $display4-size;
  font-size: calc(2rem + 2vw);
  font-weight: $display4-weight;
  letter-spacing: -1.5px;

  @include media-breakpoint-up(sm) {
    font-size: $display4-size;
  }

  .profile-title + & {
    margin-top: 0;
  }
}

.profile-name-long {
  font-size: 1.75rem;
}

.profile-section-contact {
  .profile-fullname {
    margin-bottom: spacer(2);
    margin-right: 0;
  }

  @include media-breakpoint-up(sm) {
    .profile-title {
      font-size: $h4-font-size;
    }

    .profile-name {
      font-size: $h1-font-size;
    }
  }

  @include media-breakpoint-up(lg) {
    .profile-types .list-inline-item {
      display: block;
      margin-bottom: spacer(1);
    }
  }
}

.profile-suffixes {
  display: inline-block;
  font-size: $h5-font-size;
  color: $text-muted;
  margin-bottom: spacer(2);
}

.profile-association {
  margin-bottom: 0.25rem;
}

.profile-association-group {
  margin-left: 0.1em;
  padding-right: 0.1em;

  &:first-of-type {
    margin-left: 0;
  }
}

.profile-navigator {
  background: $body-bg;
  margin-bottom: spacer(3);

  .navbar-profile-wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .profile-types {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

@mixin hidden-profile-details-mini() {
  transform: translateY(-100%);
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.profile-details-mini {
  @include hidden-profile-details-mini();
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s;

  .in-profile & {
    max-height: 100vh;
    opacity: 1;
    padding-top: $navbar-padding-y / 2;
    padding-bottom: $navbar-padding-y / 2;
    transform: translateY(0);
    transition: all 0.35s, max-height 0s;
  }

  .in-contact & {
    @include hidden-profile-details-mini();
  }

  .profile-mini-photo {
    height: 50px;
    width: 50px;
  }

  .profile-mini-identity {
    margin-right: spacer(3);
    margin-bottom: 0;
  }

  .profile-mini-name {
    margin-bottom: 0;
  }

  .media-body {
    display: flex;
    align-items: center;
  }
}

.profile-types-role {
  font-size: $font-size-sm;
  text-transform: uppercase;
  line-height: 1;
}

@include media-breakpoint-up(md) {
  .profile-biography {
    line-height: 1.8;
  }
}

.profile-overview-subheading {
  margin-bottom: spacer(2);
  font-size: $h6-font-size;
  text-transform: uppercase;
}

.profile-section {
  margin-bottom: spacer(4);
}

.section-heading {
  display: flex;
  margin-bottom: spacer(3);
  align-items: center;
  border-bottom: $border-width * 2 solid $gold;
}

.section-heading h2 {
  margin-bottom: 0;
  font-weight: 300;
}

.section-heading-desc {
  display: inline-block;
  margin-left: auto;
}

.profile-subsection {
  padding-top: spacer(3);
  padding-bottom: spacer(3);
  border-bottom: $border-width solid $border-color;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0 none;
  }
}

.section-subheading {
  @include text-compress(1.2);
  position: sticky;
  top: $profile-navigator-height;
  font-family: $font-family-serif;
}

.navbar.navbar-profile {
  padding: 0;
  border-top: $border-width solid $gray-200;
  border-bottom: $border-width solid $gray-200;
  background-color: $navbar-profile-bg;
  letter-spacing: -0.01em;

  .in-profile & {
    border-bottom-color: $gray-300;
    box-shadow: $box-shadow-sm;
  }

  .in-contact & {
    border-top: 0 none;
  }

  .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  @include media-breakpoint-down(sm) {
    .navbar-collapse {
      overflow-x: scroll;
      @include hide-scrollbars;
    }

    .nav-link {
      padding: 0.6rem 0.75rem;

      &::after {
        margin-top: 0;
      }
    }
  }
}

.navbar-profile-all {
  .dropdown-menu {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

.navbar-profile-all-toggle {
  border-left: $border-width solid $border-color;
  padding-left: spacer(1) !important;
  padding-right: spacer(1) !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -$spacer;
    width: $spacer;
    height: 100%;
    background: linear-gradient(
      to right,
      change-color($navbar-profile-bg, $alpha: 0) 0,
      $navbar-profile-bg 100%
    );
  }
}

@include media-breakpoint-up(md) {
  .profile .map-collaboration {
    height: 50vh;
  }
}
