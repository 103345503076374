@import '../core';

$box-spacer: $spacer;
$box-top-offset: $box-spacer * 1.5;
$box-responsive-factor: 1.5;

.featured-categories {
  margin-bottom: $box-spacer;
}

.featured-categories-title {
  margin-bottom: $headings-margin-bottom;
  vertical-align: text-top;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
  @include font-size($h3-font-size);
}

.featured-categories-items {
  a {
    color: white;
  }

  > * {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $box-spacer / 2;
    border: 0 none;
    padding-top: $box-spacer;
    padding-bottom: $box-spacer;
    line-height: 1.2;
    background: $primary;
    text-align: left;
    color: $white;
  }

  > :nth-child(1) .btn {
    background: $dark-blue;
  }

  > :nth-child(2) .btn {
    background: $mid-blue;
  }

  > :nth-child(3) .btn {
    background: $blue;
  }
}

@include media-breakpoint-up(md) {
  .featured-categories {
    margin-bottom: $box-top-offset + $box-spacer + $spacer;
    text-align: center;
  }

  .featured-categories-title {
    @include font-size($h2-font-size);
  }

  .featured-categories-items {
    justify-content: center;
    font-size: $font-size-base * $box-responsive-factor;
    background-color: $light;

    .btn {
      display: flex;
      position: relative;
      top: $box-top-offset;
      padding-top: $box-spacer * $box-responsive-factor;
      padding-bottom: $box-spacer * $box-responsive-factor;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      text-align: center;
    }
  }
}
