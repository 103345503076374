@import './core';

$leaflet-panel-z-index: 1000;

.explore-map {
  position: relative;
  border-bottom: $border-width solid $light;
  margin-bottom: $spacer;

  .map-legend {
    bottom: 3.5rem;
  }

  .profile-mini {
    max-width: 25%;
    position: relative;
  }

  .profile-mini-img {
    border-radius: 50%;
    border: $border-width * 3 solid currentcolor;
  }
}

.explore-map-header,
.explore-map-footer {
  position: absolute;
  z-index: 1000;
  width: 100%;
  padding: $spacer * 0.75;
  color: $white;
  text-shadow: $shadow-tight;
  z-index: $leaflet-panel-z-index - 1;
}

.explore-map-header {
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.explore-map-footer {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
}

.card-news {
  height: 100%;
  min-height: 25vh;

  .card-img-top {
    height: 100%;
    max-height: 25vh;
    object-fit: cover;
    filter: brightness(50%);
  }
  .card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: $card-spacer-x;
    overflow: hidden;
    line-height: 1.1;
    text-align: center;
    font-family: $font-family-serif;
    color: inherit;
  }
  .card-text {
    max-height: 150px;
    overflow: hidden;
    text-align: justify;
    line-height: 1.1;
  }
  .card-footer {
    position: relative;
  }
}
.card-news-header {
  flex-grow: 1;
  position: relative;
  background-color: $dark;
  color: white;
  text-shadow: $shadow-tight;
}

.explore-go-further .media img {
  object-fit: cover;
  width: 100px;
  height: 100px;
}

.explore-wordcloud {
  height: 100%;
  width: 100%;

  @include media-breakpoint-up(lg) {
    height: 20rem;
  }
}
