@import './core';

.login-profile-why {
  margin-top: $spacer / 2;
  margin-bottom: $spacer;

  h2 {
    display: table;
    white-space: nowrap;
    background-color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 90%;

    span {
      padding: 0.5em;
    }

    &::before,
    &::after {
      border-top: $border-width * 2 solid $gray-600;
      content: '';
      display: table-cell;
      position: relative;
      top: 0.5em;
      width: 45%;
    }
    &::before {
      right: 1.5%;
    }
    &::after {
      left: 1.5%;
    }
  }
}
