@import '../core';

.dropdown-hover {
  @include caret(down);
  margin-right: $caret-spacing + $caret-width;

  &::after {
    position: absolute;
    top: 45%;
    top: subtract(50%, $caret-width / 2);
    right: 0;
    margin-left: $caret-spacing;
  }

  &.nav-item::after {
    // Navbar text + padding + half caret width
    top: 1em + 0.5em + $caret-spacing / 2;
  }

  .dropdown-toggle::after {
    border: 0 none;
  }

  .dropdown-menu.show {
    display: none;
  }

  &:hover .dropdown-menu.show {
    display: block;
  }
}
