@import "../core.scss";

.brand-logo {
  white-space: nowrap;
}

.brand-logo-img {
  height: 3rem;
}

.brand-logo-sitename {
  display: inline-block;
  max-width: 25vw;
  white-space: normal;
  vertical-align: text-top;
  margin-left: $spacer / 2;
  padding-left: $spacer * .375;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-left: 1px solid $gray-500;
  font-size: 80%;
  line-height: 1.1;
}

@include media-breakpoint-up(md) {
  .brand-logo-img {
    height: 4rem;
  }
  .brand-logo-sitename {
    margin-left: $spacer;
    padding-left: $spacer * .75;
    font-size: 100%;
  }
}

