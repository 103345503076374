@import './core.scss';
.btn svg {
  flex-shrink: 0;
}

.navbar.navbar-secondary .nav-link.active::after {
  background-color: $dark;
}

.mini-photo {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
}

/* Features for consideration for the JCU CookBook */
.text-black {
  color: #000 !important;
}
.text-underline {
  text-decoration: underline !important;
}
.text-compress {
  @include text-compress;
}
.text-compress-more {
  @include text-compress(2);
}
.text-shadow {
  @include text-shadow;
}

.link-light {
  color: $light;

  @include hover() {
    color: $bright-orange;
  }
}

.border-3 {
  border-width: $border-width * 3;
}

.border-input {
  border-color: $input-border-color !important;
}

.lh-short {
  line-height: 1.2;
}

.link-implicit {
  color: inherit;

  &:hover {
    border-bottom: 1px solid currentcolor;
  }
}

// Remove when BS5 is available https://blog.getbootstrap.com/2020/06/16/bootstrap-5-alpha/#utilities-api
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }

    .mw#{$infix}-50 {
      max-width: 50% !important;
    }

    .mw#{$infix}-75 {
      max-width: 75% !important;
    }
  }
}

.col-fit {
  // Consider     display: -ms-grid; -ms-grid-columns: min-content;
  // for IE support
  width: min-intrinsic;
  width: -moz-min-content;
  width: -webkit-min-content;
  width: min-content;
  flex-grow: 1;
}

.btn-group-flex {
  display: flex;
  flex-wrap: wrap;

  .btn {
    flex: auto;
    margin-right: spacer(2);
    margin-bottom: spacer(2);
  }
}

.btn-md {
  display: block;
  width: 100%;
  margin-bottom: $btn-block-spacing-y;
}

@include media-breakpoint-up(md) {
  .btn-md {
    display: inline-block;
    width: auto;
  }

  .border-top-md-none {
    border-top: 0 none !important;
  }

  .border-left-md {
    border-left: $border-width solid $border-color !important;
  }
}
@include media-breakpoint-up(lg) {
  .mw-lg-50 {
    max-width: 50% !important;
  }
}

.hyphens-auto {
  hyphens: auto !important;

  @include media-breakpoint-up(md) {
    hyphens: inherit !important;
  }
}

.navbar {
  .nav-link {
    @include media-breakpoint-up(sm) {
      padding-top: 0.95rem;
      padding-bottom: 0.7rem;
    }

    font-weight: 400;

    &.active {
      font-weight: 600;
      letter-spacing: -0.02em;
    }

    &::after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.15rem auto 0;
      background: transparent;
      transition: width 0.15s ease, background-color 0.15s ease;
    }

    &:hover::after {
      width: 100%;
      background-color: $dark;
    }

    &.active::after,
    &.active:hover::after {
      width: 100%;
      background-color: $gold;
    }
  }
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle-implicit::after {
  display: none;
}
