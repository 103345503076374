@import '../core';

.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 1400px) {
  .hero-bg-constrain {
    background-size: 1400px;
    background-position-x: center !important;
  }
}

.hero-has-bg {
  background-color: $dark;
  color: $white;
}
