@import '../core';

.data-explorer .custom-checkbox {
  .custom-control-input,
  .custom-control-label {
    cursor: pointer;
  }
}

@include media-breakpoint-up(md) {
  .data-explorer-counts {
    font-size: $h5-font-size;
  }
}

.data-explorer-filter {
  padding-top: spacer(1);
  padding-bottom: spacer(1);
  border: 0 none;
  border-bottom: $input-border-width * 3 solid $input-border-color;
}

.data-explorer-filter-active {
  border-bottom-color: $primary;
}

.data-explorer-pagination {
  padding-top: spacer(2);
  border-top: $border-width solid $border-color;
}
