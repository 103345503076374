@mixin text-compress($factor: 1) {
  letter-spacing: -0.03em * $factor;
}

@mixin text-shadow {
  text-shadow: 1px 1px 2px #000;
}

@mixin filter-shadow {
  filter: drop-shadow(1px 1px 2px #000);
}

@mixin box-shadow {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
}

@mixin hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  ::-webkit-scrollbar {
    display: none; /* Chrome and Safari */
  }
}
