@import '../core';

.supervision-card {
  height: 100%;

  .card-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0 none;
    background-color: transparent;
  }

  .card-footer + .card-body {
    padding-bottom: 0;
  }

  .card-footer {
    border-top: 0 none;
    text-align: right;
    background-color: transparent;
  }
}

.supervision-grid-item {
  margin-bottom: spacer(2);

  .supervision-card-status {
    max-width: 75%;
    line-height: 1.1;
  }
}

.supervision-card-completion {
  flex-shrink: 0;
}

.supervision-list-item {
  padding-bottom: spacer(2);

  @include media-breakpoint-up(sm) {
    padding-bottom: spacer(3);
  }

  &:not(:first-child) {
    padding-top: spacer(2);

    @include media-breakpoint-up(sm) {
      padding-top: spacer(3);
    }
  }

  &:not(:last-child) {
    border-bottom: $border-width solid $border-color;
  }

  @include media-breakpoint-down(xs) {
    .supervision-card-status,
    .supervision-card-completion {
      display: inline-block;
      margin-right: spacer(3);
    }
  }
}

.supervision-card-photo {
  margin-right: spacer(3);
}

.supervision-card-identity {
  margin-bottom: spacer(1);
}

.supervision-card-name {
  font-size: $h4-font-size;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.supervision-card-type {
  color: $text-muted;
}

.supervision-data.data-explorer-view-grid .data-explorer-pagination {
  padding-top: 0;
  border-top: 0 none;
}
