@import '../core.scss';

$breadcrumb-color: $white;

.breadcrumbs {
  @include text-shadow;
  position: absolute;
  color: $breadcrumb-color;

  a {
    color: $breadcrumb-color;
  }

  svg {
    @include filter-shadow;
  }

  .breadcrumb {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }

  .breadcrumb-item {
    + .breadcrumb-item::before {
      content: '/';
      color: $bright-orange;
    }

    &.active {
      color: $breadcrumb-color;
    }
  }
}
