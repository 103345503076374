@import '../core';

.profile-mini {
  align-items: center;

  .profile-types {
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }
}

.profile-mini-identity {
  display: block;
  margin-bottom: spacer(1);
  line-height: 1;
}

.profile-mini-name {
  margin-left: -0.05em;
  font-size: $h5-font-size;
  font-weight: $font-weight-normal;
}

.profile-mini-name-long {
  font-size: 80%;
}

.profile-mini-photo {
  margin-right: spacer(2);
}
