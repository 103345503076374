@import '../core';

.person-cards {
  .col {
    margin-bottom: spacer(2);
  }

  .card-body {
    padding: 0;
  }

  .profile-mini-photo {
    border-right: $border-width solid $light;
  }
}
