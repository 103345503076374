@import '../core';

.output {
  align-items: start;
  padding-bottom: spacer(2);

  &:not(:first-child) {
    padding-top: spacer(2);
  }

  @include media-breakpoint-up(sm) {
    padding-bottom: spacer(3);

    &:not(:first-child) {
      padding-top: spacer(3);
    }
  }

  &:not(:last-child) {
    border-bottom: $border-width solid $gray-200;
  }
}

.author-self {
  font-weight: 600;
  border-bottom: $border-width dotted $gray-900;
}

.output-classifier {
  @include media-breakpoint-up(md) {
    max-width: 60%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 40%;
  }
}

.output-badges {
  border-left: $border-width solid $gray-200;
}

.altmetric {
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    margin-bottom: spacer(2);
  }

  &.altmetric-hidden {
    margin-bottom: 0;
  }
}
