@import '../core';

.teaching-subject {
  align-items: center;
  padding-top: spacer(2);
  padding-bottom: spacer(2);

  &:not(:last-child) {
    border-bottom: $border-width solid $light;
  }
}

.teaching-subject-code {
  width: calc(2em + 4ch);
  flex-shrink: 0;
  text-align: center;
}
