@import './core';

.contact-us {
  .row {
    align-items: stretch;
  }
  .col {
    margin-bottom: spacer(3);
  }
  .card {
    height: 100%;
  }
}
