@import '../core.scss';

.hero-intro {
  .jumbotron {
    @include text-shadow;
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 0;
    background: hero-gradient-background-sm($fadeTop: false);
  }

  .lead {
    line-height: $line-height-base * 0.8;
  }

  svg {
    @include filter-shadow;
  }
}

.breadcrumbs-wrapper + .hero-intro .jumbotron {
  background: hero-gradient-background($fadeTop: true);
}

.hero-intro-heading {
  margin-left: -0.05em;
  margin-bottom: $headings-margin-bottom * 0.5;
  font-size: $h2-font-size;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

@include media-breakpoint-up(sm) {
  .hero-intro .jumbotron {
    padding-top: 10rem;
    padding-bottom: spacer(2);
  }
}

@include media-breakpoint-up(lg) {
  .hero-intro {
    .jumbotron {
      padding-top: 16rem;
    }

    .lead {
      line-height: $line-height-base;
    }
  }
  .hero-intro-heading {
    @include text-compress;
    font-size: $display4-size;
  }
}
