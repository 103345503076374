@import '../core';

$showcase-border: $border-width solid $border-color;
$showcase-spacer-y: spacer(3);
$showcase-spacer-md-y: spacer(2);

.showcase-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 75%;
  }

  &:not(:first-child)::before,
  &:only-child::before {
    border-top: $showcase-border;
    padding-top: $showcase-spacer-y;
  }

  &:not(:last-child)::after,
  &:only-child::after {
    padding-bottom: $showcase-spacer-y;
    border-bottom: $showcase-border;
  }

  @include media-breakpoint-up(md) {
    padding-top: $showcase-spacer-md-y;
    padding-bottom: $showcase-spacer-md-y;
    border-left: $showcase-border;

    &:not(:only-child):first-child {
      border-left: 0 none;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.showcase-figure {
  margin-top: -1 * spacer(1);
  line-height: 1;
  font-size: $display4-size;
  font-weight: $display4-weight;
  letter-spacing: -0.06em;
}

.showcase-symbol {
  font-size: 70%;
  vertical-align: super;
  letter-spacing: -4px;
}

.showcase-unit {
  margin-left: auto;
  margin-right: auto;
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $headings-line-height;
}
