@import '../core';

$timeline-spacer-x: 1rem;
$timeline-spacer-y: 1rem;
$timeline-border-width: 3px;
$timeline-color: $gold;
$timeline-badge-size: 60px;
$timeline-badge-font-size: 18px;
$timeline-arrow-size: 14px;
$timeline-now-color: $primary;
$timeline-panel-bg-color: $white;
$timeline-panel-border-width: $border-width;
$timeline-panel-border-color: $gray-500;
$timeline-panel-border-radius: $spacer / 2;

.timeline {
  position: relative;
  list-style: none;
  padding-left: $timeline-badge-size / 2;

  // Timeline background "line"
  &::before {
    content: ' ';
    position: absolute;
    top: $timeline-badge-size - 1px;
    bottom: $timeline-badge-size - 1px;
    width: $timeline-border-width;
    margin-left: -$timeline-border-width / 2;
    background-color: $timeline-color;
  }
}

.timeline-entry {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: $timeline-spacer-y;

  &:last-child {
    margin-bottom: 0;
  }
}

.timeline-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transform: translateX(-$timeline-badge-size / 2);
  width: $timeline-badge-size;
  height: $timeline-badge-size;
  border: $timeline-border-width solid $timeline-color;
  border-radius: 50%;
  background-color: $white;
  text-align: center;
  font-size: $timeline-badge-font-size;
  color: $dark;
}

.timeline-badge-now {
  background-color: $timeline-now-color;
  border-color: $timeline-now-color;
  color: $white;
}

.timeline-panel {
  position: relative;
  flex: 1 1;
  padding: $timeline-spacer-y $timeline-spacer-x;
  border: $timeline-panel-border-width solid $timeline-panel-border-color;
  border-radius: $timeline-panel-border-radius;
  background-color: $timeline-panel-bg-color;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  // Arrow
  &::after,
  &::before {
    content: ' ';
    top: 50%;
    right: 100%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-right-color: $timeline-panel-bg-color;
    border-width: $timeline-arrow-size;
    margin-top: -$timeline-arrow-size - $timeline-panel-border-width;
  }

  &::before {
    border-right-color: $timeline-panel-border-color;
    border-width: $timeline-arrow-size * 1.1;
    margin-top: -$timeline-arrow-size * 1.1;
  }
}
