@import '../core';

.jumbotron-feature {
  height: 72vh;
  min-height: 400px;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  background: hero-gradient-background($fadeTop: true);
  letter-spacing: -0.01em;
}

.jumbotron-feature > .container-lg,
.feature-inner {
  height: 100%;
}

.feature-identity {
  position: absolute;
  left: 0;
  bottom: 1.25rem;
  max-width: 90%;
  text-align: left;
  color: #fff;

  a {
    color: #fff;
  }
}

.feature-identity-name {
  line-height: 1.25;
  margin-bottom: spacer(1);
}

.feature-identity-byline {
  line-height: 1.3;
}

.feature-title {
  margin-bottom: 0;
  line-height: 1;
  font-size: 2.5rem;
  font-size: calc(1.5rem + 2vw);
  letter-spacing: -0.03em;
}

.feature-byline .lead {
  line-height: 1.3;
  text-align: center;
}

.row-search {
  position: absolute;
  top: calc(100% - 0.5rem);
  left: $grid-gutter-width / 2;
  right: $grid-gutter-width / 2;
}

@include media-breakpoint-down(sm) {
  .feature-inner-inlay[style] {
    background: transparent !important;
  }
  .jumbotron-feature {
    padding-top: spacer(3);
  }
  .row-search .input-group {
    border: ($border-width * 3) solid $primary;
  }
}

@include media-breakpoint-up(md) {
  .jumbotron-feature {
    background: hero-gradient-background($fadeTop: false);
    height: auto;
    letter-spacing: initial;
  }
  .feature-identity {
    position: relative;
    max-width: 40%;
    padding: 0.75rem 1rem;
    font-size: 80%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .feature-identity-title {
    margin-bottom: -0.25rem;
  }
  .feature-inner {
    margin-top: 10rem;
  }
  .feature-inner-inlay {
    margin-bottom: $input-height-lg;
    padding-top: $spacer;
    padding-left: $spacer;
    padding-right: $spacer;
    background-color: change-color($primary, $alpha: 0.85);
  }
  .row-search {
    position: static;
    transform: translateY(50%);
  }
  .about-portfolio a {
    text-decoration: underline;
    color: $white;

    &:hover {
      color: $gold;
    }
  }
}

@include media-breakpoint-up(lg) {
  .feature-identity {
    max-width: 30%;
  }

  .feature-inner {
    margin-top: 16rem;
  }

  .feature-title {
    font-size: 3rem;
  }
}
