@import '../core';

.discipline-card {
  height: 100%;

  .card-img-top {
    height: 200px;
    object-fit: cover;
    object-position: center 25%;
    filter: brightness(65%);
  }

  .card-title,
  .card-footer,
  .list-group-item {
    display: flex;
    justify-content: space-between;
  }

  .card-title {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: flex-end;
    padding: $card-spacer-y $card-spacer-x;
    font-size: $h5-font-size;
    line-height: 1.1;
    color: $white;
    letter-spacing: -0.05em;
    text-shadow: $shadow-tight;

    > svg {
      filter: drop-shadow($shadow-tight);
    }
  }

  .card-footer,
  .list-group-item {
    align-items: center;
  }

  a {
    color: $body-color;
  }
}

.discipline-card-header {
  position: relative;
  background: $gray-600;
}
