@import '../core';

.leaflet-container a.leaflet-popup-close-button {
  color: $dark;
}

.map-collaboration {
  height: 75vh;

  .leaflet-popup-content {
    p {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    ul {
      padding-left: 0;
      list-style-position: inside;
    }
  }

  .leaflet-marker-icon {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
  }
}

.leaflet-marker-icon-jcu {
  filter: drop-shadow(1px 1px 2px change-color($primary, $alpha: 0.5));
}
