@import '../core';

$media-contact-border: $border-width * 2 solid $gray-500;

.modal-media-enquiries {
  .contact-card-entry {
    margin-bottom: spacer(2);
  }

  .person-cards {
    .profile-mini-photo {
      height: 100%;
      width: 64px;
      align-self: center;
    }

    .profile-mini-name {
      margin-bottom: 0;
    }
  }
}

.media-enquiries-choices {
  > .col {
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
  }

  > .col:first-child {
    padding-right: spacer(3);
  }

  > .col:last-child {
    margin-top: 1rem;
    padding-top: 1rem;
    padding-left: spacer(3);
    border-top: $media-contact-border;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-top: 0;
      border-top: 0 none;
      border-left: $media-contact-border;
    }

    &::before {
      content: 'or';
      position: absolute;
      top: -0.2em;
      left: 50%;
      padding-right: 0.5em;
      padding-left: 0.5em;
      color: $dark;
      background-color: $white;
      transform: translateY(-50%) translateX(-50%);

      @include media-breakpoint-up(lg) {
        top: 50%;
        left: -0.1em;
      }
    }
  }
}
