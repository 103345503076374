@import '../core';

.alert-demo {
  margin-bottom: 0;
  padding: spacer(1) 0;
  border: 0 none;
  text-align: center;
  background-color: $gold;

  a {
    color: $gray-800;
    text-decoration: underline;
    &:hover {
      color: $black;
    }
  }
}
