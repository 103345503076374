@import '../core';

.steps {
  counter-reset: step-counter 0;
}

.step-number::before {
  display: inline-block;
  width: 100%;
  content: counter(step-counter, decimal);
  counter-increment: step-counter;
  line-height: 0.8;
  text-align: center;
  font-size: $display4-size;
  font-size: calc(3rem + 2vw);
  font-weight: 300;
  letter-spacing: -0.05em;
}
