@import "../core";

.header {
  border-bottom: $border-width solid $gray-200;
}

@include media-breakpoint-up(lg) {
  .header {
    border-bottom: 0 none;
  }
}

@include media-breakpoint-down(md) {
  .navbar-menu .container-lg {
    flex-direction: column;
    align-items: stretch;
  }
}
