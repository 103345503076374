@import '../core';

.funding {
  padding-bottom: spacer(3);

  &:not(:first-child) {
    padding-top: spacer(3);
  }

  &:not(:last-child) {
    border-bottom: $border-width * 3 solid $gray-200;
  }
}

.funding-section:not(:last-child) {
  margin-bottom: spacer(2);
  padding-bottom: spacer(2);
  border-bottom: $border-width solid $gray-200;
}

.funding-details {
  justify-content: center;
  align-items: center;
  margin-bottom: spacer(2);
}

.author-self {
  font-weight: 600;
  border-bottom: 1px dotted;
}
