@import '../core';

$footer-border-color: $gray-400;
$footer-list-divider-color: $gray-500;

.footer-cols > :not(:first-child) > ul {
  padding-top: spacer(2);
  border-top: $border-width solid $footer-border-color;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
    padding-left: spacer(3);
    border-top: 0 none;
    border-left: $border-width solid $footer-border-color;
  }

  @include media-breakpoint-up(lg) {
    padding-left: spacer(4);
  }
}

.img-flag {
  max-height: 30px;
}

.footer-partners {
  border-bottom: $border-width solid $footer-border-color;
}

.list-inline-bordered .list-inline-item:not(:last-child) {
  border-right: $border-width solid $footer-list-divider-color;
  margin-right: 1em;
  padding-right: 1em;
}

@include media-breakpoint-up(lg) {
  .list-business-info > li {
    display: inline-block;
  }

  .list-business-info > li:not(:last-child) {
    border-right: $border-width solid $footer-list-divider-color;
    margin-right: 1em;
    padding-right: 1em;
  }
}
