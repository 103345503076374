@import '../core';

.collage-avatar-img {
  width: 100%;
  height: auto;
  z-index: 0;
  border: 0 solid transparent;
  transition-property: border, transform;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
}

.collage-avatar-tooltip {
  pointer-events: none;
  top: calc(1rem + 1.5vw) !important;
  line-height: 1.2;
}

.collage-avatar:hover {
  z-index: 1;

  .collage-avatar-img {
    transform: scale(1.2);
    border: 4px solid $primary;
  }
}

@include media-breakpoint-only(xs) {
  // 5x3
  .collage-avatar {
    flex-basis: 20%;
  }
  .collage-avatar:nth-child(n + 16) {
    display: none;
  }
}

@include media-breakpoint-only(sm) {
  // 6x3
  .collage-avatar {
    flex-basis: 16.6667%;
  }
  .collage-avatar:nth-child(n + 19) {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  // 8x3
  .collage-avatar {
    flex-basis: 12.5%;
  }
  .collage-avatar:nth-child(n + 25) {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  // 10x2
  .collage-avatar {
    flex-basis: 10%;
  }
  .collage-avatar:nth-child(n + 21) {
    display: none;
  }
}
