@import '../core';

.profile-types {
  margin-bottom: 0;
}

.profile-types-orcidid {
  white-space: nowrap;

  @include media-breakpoint-down(md) {
    letter-spacing: -0.6px;
  }
}
