@function hero-gradient-background($fadeTop: true) {
  @if $fadeTop {
    @return linear-gradient(
      rgba(0, 0, 0, 0.75) 3%,
      rgba(0, 0, 0, 0.6) 10%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.7) 75%,
      rgba(0, 0, 0, 0.95) 95%
    );
  }
  @return linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 90%);
}

@function hero-gradient-background-sm($fadeTop: true) {
  @if $fadeTop {
    @return linear-gradient(
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.75) 10%,
      rgba(0, 0, 0, 0) 22%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.5) 75%,
      rgb(0, 0, 0) 95%
    );
  }
  @return linear-gradient(rgba(0, 0, 0, 0) 30%, rgb(0, 0, 0) 85%);
}

@function spacer($key: 3) {
  @return map-get($spacers, $key);
}
