@import '../core';

.profile-showcase {
  align-items: stretch;
  justify-content: center;
  margin-bottom: spacer(3);

  .contact-card-entry {
    margin-bottom: spacer(1);
  }

  .contact-card-entry-icon {
    margin-right: spacer(2);
  }
}
