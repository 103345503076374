@import '../core';

.profile-featured {
  margin-bottom: $card-spacer-y * 2;
  border-bottom: $card-border-width solid $card-border-color;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0 none;
  }

  .card {
    border: 0 none;
  }

  .card-body {
    padding: 0;
  }

  .card-footer {
    padding-top: 0;
    background-color: transparent;
    border: 0 none;
    text-align: right;
  }
}

.profile-featured-name {
  margin-bottom: spacer(1);
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
  line-height: 1;
  /* Will not auto-hyphenate a capitalised name, requires &shy; */
  hyphens: auto;
  letter-spacing: -0.05em;
}

@include media-breakpoint-up(lg) {
  .profile-featured {
    margin-bottom: 0;
    border-right: $card-border-width solid $card-border-color;
    border-bottom: 0 none;

    &:last-child {
      border-right: 0 none;
    }

    .card {
      height: 100%;
    }

    .card-body {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }

    .card-footer {
      padding-bottom: 0;
    }
  }

  .profile-featured-img {
    margin-bottom: $card-spacer-y;
  }

  .profile-featured-name-long {
    font-size: $h4-font-size;
    letter-spacing: -0.1em;
  }
}
