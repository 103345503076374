@import '../core';

.contact-card-entry {
  margin-bottom: spacer(3);
}

.contact-card-entry-icon {
  height: 2em;
  width: 2em;
  flex-shrink: 0;
  margin-right: spacer(2);

  @include media-breakpoint-up(sm) {
    margin-right: spacer(3);
  }
}
