@import '../core';

.hero-cta {
  text-align: center;

  // Ensure CTA is flush against the next element
  & > .jumbotron {
    margin-bottom: 0;
  }

  &.hero-has-bg {
    @include text-shadow;

    > .jumbotron {
      background-color: rgba(0, 0, 0, 0.45);
      padding-top: 15vw;
      padding-bottom: 15vw;

      @include media-breakpoint-up(md) {
        padding: 7rem;
      }
    }

    .btn {
      @include box-shadow();
    }
  }
}

.hero-cta-title {
  margin-bottom: spacer(3);
  line-height: 1;
}

@include media-breakpoint-up(md) {
  .hero-cta-action {
    font-size: $btn-font-size;
  }
}
