@import '../core';

.read-more-checkbox {
  display: none;
}

.read-more-label {
  display: block;
  text-align: right;
  cursor: pointer;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &::before {
    content: 'Read more…';

    .read-more-checkbox:checked ~ & {
      content: 'Read less…';
    }
  }
}

.read-more-content {
  position: relative;
  overflow-y: hidden;

  .read-more-checkbox ~ &::after {
    content: '';
    position: absolute;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    bottom: 0;
    left: 0;
    height: 3.6em;
  }

  .read-more-checkbox:checked ~ & {
    overflow-y: visible;

    // Allow max-height to be set in component
    &,
    &[style] {
      max-height: none !important;
    }

    &::after {
      display: none;
    }
  }
}
