@import '../core';

$map-legend-icon-width: 1.375rem;
$map-legend-icon-height: 2rem;

.map-legend {
  background: rgba(0, 0, 0, 0.7);
  padding: $spacer / 2;
  color: $white;
  font-size: 1.5em;

  img {
    margin-right: $spacer / 2;
    max-width: $map-legend-icon-width;
  }
}

.map-legend-entry {
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.leaflet-container .tropics-marker-link {
  color: $bright-green;
  text-decoration: underline;

  &:hover {
    color: $light-emerald;
  }
}
